@import 'vars';
@import 'base';
@import 'typography';

.content-wrapper {
  max-width: 95vw;
  margin: 0 auto;
}

.header {
  padding: 2rem 0 1em;

  .content-wrapper {
    display: flex;
    max-width: 95vw;
    justify-content: space-between;
  }
}

.main {
  margin-top: 3rem;
}

.footer {
  padding: 3rem 0;
  margin-top: auto;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.header__logo {
  display: inline-flex;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  background-color: var(--color--primary);
  background-image: url('/logo-white-transparent.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
}

.header__site-title-link {
  border: 0;
}

.header__site-title {
  display: flex;
  align-items: center;
  margin: 0;
  color: var(--color--primary);
  font-family: var(--serif);
  font-size: 1.5rem;
  line-height: 1;
}

.header__nav {
  display: flex;
  align-items: center;
  font-family: var(--sans-serif);
  text-transform: uppercase;

  a {
    border-color: transparent;
    font-size: 0.8rem;

    &:hover {
      border-color: inherit;
    }
  }

  > * + * {
    // margin-left: 1.5rem;
  }
}

.footer__nav {
  display: flex;
  font-family: var(--sans-serif);
  text-transform: uppercase;

  a {
    border-color: transparent;
    font-size: 0.8rem;

    &:hover {
      border-color: inherit;
    }
  }

  > * + * {
    // margin-left: 1.5rem;
  }
}

.copyright {
  margin-top: 2rem;
  color: var(--color--grey-med);
  font-size: 0.8rem;
  font-style: italic;
}
