:root {
  // Typography
  --sans-serif: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
    helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

  --serif: Iowan Old Style, Apple Garamond, Baskerville, Times New Roman,
    Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;

  --mono: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;

  // Colours
  --color--primary: #e74c3c;
  --color--black: #000;
  --color--grey-dark: rgb(70, 70, 70);
  --color--grey-med: rgb(130, 130, 130);
  --color--grey-light: rgb(200, 200, 200);
}
