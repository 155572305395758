html,
body {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  font-family: var(--serif);
  font-size: calc(15px + 0.25vw);
}

body {
  position: relative;
  font-size: 1rem;
  line-height: 1.625;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  border-bottom: 2px solid;
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}

html,
body,
#__next {
  height: 100%;
}

#__next {
  display: flex;
  flex-direction: column;
}
