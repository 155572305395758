h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0 0;
}

h1 {
  font-family: var(--sans-serif);
  font-size: calc(1.5rem + 3vw);
  line-height: 1.5;
}

h2 {
  font-family: var(--sans-serif);
  font-size: calc(1.5rem + 2vw);
  line-height: 1.25;
}

blockquote {
  padding: 0 2rem;
  border-left: 5px solid;
  margin: 3rem 0;
  font-family: var(--sans-serif);
  font-style: italic;
}

code {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--color--black);
  color: #fff;
  font-family: var(--mono);
}

pre {
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  background-color: var(--color--black);
  color: #fff;
  white-space: pre-wrap;

  code {
    background-color: unset;
  }
}

img + em {
  display: block;
  color: var(--color--grey-med);

  a {
    color: var(--color--grey-dark);
  }
}
